import styled from "styled-components";
import media from "@styles/media";

const Footer = styled.footer`
  height: 70px;
  background-color: whitesmoke;
  width: 100%;
  ${media.toTabletP`
    height: 100%;
    padding: 20px 0;
  `}
`;

export default Footer;
