import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

interface NavbarLinkProps {
  title: string;
  to: string;
  isExternal?: boolean;
}

const StyledNavbarLink = styled.div`
  padding: 5px;
  margin: 0 25px;
  cursor: pointer;
  a {
    color: white;
    transition: all 0.3s ease-in-out;
    position: relative;
    text-decoration: none;
  }
  &:hover {
    opacity: 0.8;
    color: whitesmoke;
  }
`;

const NavbarLink: React.FC<NavbarLinkProps> = ({
  title,
  to,
  isExternal,
}) => {
  return (
    <StyledNavbarLink>
      {!isExternal ? (
        <Link to={to} title={title}>
          {title}
        </Link>
      ) : (
        <a href={to} title={title} target="_blank">
          {title}
        </a>
      )}
    </StyledNavbarLink>
  );
};

export default NavbarLink;
