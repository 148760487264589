import styled from "styled-components";
import media from "@styles/media";

const FooterLeftSide = styled.div`
  display: flex;
  ${media.toTabletP`
    display: flex;
    flex-direction: column;
    text-align: center;
  `}
`;

export default FooterLeftSide;
