import styled from "styled-components";
import media from "@styles/media";

const NavbarHamburger = styled.div`
  display: none;
  ${media.toTabletP`
    font-size: 1.5rem;
    display: block;
  `}
`;

export default NavbarHamburger;
