import styled from "styled-components";
import media from "@styles/media";;

const StyledLogo = styled.div`
  height: 60px;
  width: 80px;
  ${media.toPhone`
    height: 40px;
    width: 60px;
  `}
`;

export default StyledLogo;
