import styled from "styled-components";
import { Link } from "gatsby";
import { LinkProps, LinkTypes } from "./types";

const StyledLink = styled(Link)<LinkProps>`
  color: ${props =>
    props.type === LinkTypes.Secondary ? "#006dd1" : "black"};
  transition: all 0.3s ease-in-out;
  &:hover {
    color: ${props =>
      props.type === LinkTypes.Secondary
        ? "rgba(0, 0, 255, .7)"
        : "rgba(0, 0, 0, 0.5)"};
    opacity: 1;
  }
`;

export default StyledLink;
