import React from "react";
import StyledLogo from "./Logo.styled";
import StaticImage from "@components/StaticImage";

const Logo = () => {
  return (
    <StyledLogo>
      <StaticImage filename="logo.png" />
    </StyledLogo>
  );
};

export default Logo;
