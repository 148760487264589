import styled from "styled-components";
import media from "@styles/media";

const NavbarLinksList = styled.div`
  display: flex;
  ${media.toTabletP`
    display: none;
  `}
`;

export default NavbarLinksList;
