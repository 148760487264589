import styled from "styled-components";

interface NavbarMobileProps {
  isActive?: boolean;
}

const NavbarMobile = styled.div<NavbarMobileProps>`
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  background-color: #1a7ece;
  transform: ${props =>
    !props.isActive ? `translateX(100%)` : `translateX(0)`};
  transition: all 0.3s ease-in-out;
  z-index: 1000;
  overflow: hidden;
`;

export default NavbarMobile;
