import styled from "styled-components";
import media from "@styles/media";
import Container from "@components/Container";

const NavbarContaier = styled(Container)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-size: 1.2rem;
  font-weight: 400;
  ${media.toPhone`
    font-size: 1rem;
  `}
`;

export default NavbarContaier;
