import React from "react";
import StyledHeader from "./Header.styled";
import Navbar from "../Navbar";

interface HeaderProps {
  isFullscreen?: boolean;
  color?: string;
}

const Header: React.FC<HeaderProps> = ({
  isFullscreen,
  children,
  color,
}) => {
  return (
    <StyledHeader>
      <Navbar color={color} />
      {children}
    </StyledHeader>
  );
};

export default Header;
