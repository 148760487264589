import React from "react";
import Img from "gatsby-image";
import { StaticQuery, graphql } from "gatsby";

interface ImageProps {
  filename: string;
  alt?: string;
}

const Image: React.FC<ImageProps> = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                sizes(maxWidth: 600) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find((n: any) => {
        return n.node.relativePath.includes(props.filename);
      });
      if (!image) {
        return null;
      }

      const imageSizes = image.node.childImageSharp.sizes;
      return <Img alt={props.alt} sizes={imageSizes} />;
    }}
  />
);

export default Image;
