import React from "react";
import StyledNavbarMobile from "./NavbarMobile.styled";
import StyledNavbarMobileInner from "./components/NavbarMobileInner.styled";
import StyledNavbarMobileNav from "./components/NavbarMobileNav.styled";
import StyledNavbarMobileNavInner from "./components/NavbarMobileNavInner.styled";
import StyledNavbarHamburger from "../NavbarHamburger.styled";
import StyledNavbarMobileItems from "./components/NavbarMobileItems.styled";
import NavbarLink from "../NavbarLink";
import Logo from "@components/Logo";
import { FiX } from "react-icons/fi";

interface NavbarMobileProps {
  links?: {
    key: string;
    name: string;
    to: string;
  }[];
  handleMenuTrigger: () => void;
  isMenuActive?: boolean;
}

const NavbarMobile: React.FC<NavbarMobileProps> = ({
  links,
  isMenuActive,
  handleMenuTrigger,
}) => {
  return (
    <StyledNavbarMobile isActive={isMenuActive}>
      <StyledNavbarMobileInner>
        <StyledNavbarMobileNav>
          <StyledNavbarMobileNavInner>
            <Logo />
            <StyledNavbarHamburger onClick={handleMenuTrigger}>
              <FiX />
            </StyledNavbarHamburger>
          </StyledNavbarMobileNavInner>
        </StyledNavbarMobileNav>
        <StyledNavbarMobileItems>
          {links?.map(({ key, name, to }) => {
            return <NavbarLink key={key} title={name} to={to} />;
          })}
        </StyledNavbarMobileItems>
      </StyledNavbarMobileInner>
    </StyledNavbarMobile>
  );
};

export default NavbarMobile;
