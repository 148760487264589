import styled from "styled-components";
import media from "@styles/media";

const FooterCopyright = styled.div`
  ${media.toPhone`
    margin-top: 20px;
    text-align: center;
  `}
`;

export default FooterCopyright;
