import styled from "styled-components";
import NavbarLink from "../../NavbarLink";

const NavbarMobileItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  > div {
    font-size: 1.5rem;
    margin: 10px 0;
  }
`;

export default NavbarMobileItems;
