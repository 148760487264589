import React from "react";
import StyledFooter from "./Footer.styled";
import StyledFooterInner from "./components/FooterInner.styled";
import StyledFooterRightSide from "./components/FooterRightSide.styled";
import StyledFooterLeftSide from "./components/FooterLeftSide.styled";
import StyledFooterCopyright from "./components/FooterCopyright.styled";
import StyledFooterItem from "./components/FooterItem.styled";
import Link from "@components/Link";

interface FooterProps {}

const Footer: React.FC<FooterProps> = ({}) => {
  return (
    <StyledFooter>
      <StyledFooterInner>
        <StyledFooterLeftSide>
          <StyledFooterItem>
            <Link to="/term-and-conditions">
              Terms and Conditions
            </Link>
          </StyledFooterItem>
          <StyledFooterItem>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </StyledFooterItem>
        </StyledFooterLeftSide>
        <StyledFooterRightSide>
          <StyledFooterCopyright>
            &copy; {new Date().getFullYear()} reactemplates.com. All
            rights reserved
          </StyledFooterCopyright>
        </StyledFooterRightSide>
      </StyledFooterInner>
    </StyledFooter>
  );
};
export default Footer;
