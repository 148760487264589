import styled from "styled-components";
import media from "@styles/media";
import { NavbarProps } from "./types";

const StyledNavbar = styled.nav<
  NavbarProps & { isBlack: boolean; isVisible: boolean }
>`
  height: 128px;
  position: absolute;
  top: ${props => (props?.isVisible ? "0px" : "48px")};
  width: 100%;
  z-index: 10;
  transition: all 0.3s ease-in-out;
  ${media.toTabletP`
    height: 70px
  `}
  ${media.toMobile`
    top: 0px;
  `}

  ${props =>
    props.isBlack &&
    `position: fixed;
    height: 70px;
    background-color: rgba(25, 25, 25, .8);
    z-index: 1000;
    color: white;
    fill: white;`}
`;

export default StyledNavbar;
