import styled from "styled-components";
import media from "@styles/media";

const Container = styled.div`
  margin: 0 50px;
  ${media.toTabletP`
    margin: 0 20px;
  `}
`;

export default Container;
