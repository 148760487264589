import styled from "styled-components";

const NavbarMobileNavInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 100%;
`;

export default NavbarMobileNavInner;
