import styled from "styled-components";
import media from "@styles/media";

const FooterInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 70px;
  height: 100%;
  ${media.toTabletP`
    flex-direction: column
  `}
`;

export default FooterInner;
