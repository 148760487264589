export enum LinkTypes {
  Primary = "primary",
  Secondary = "secondary",
}

export interface LinkProps {
  text?: string;
  title?: string;
  type?: LinkTypes;
  to: string;
}
