/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function getBaseURL() {
  const url = process.env.GATSBY_URL;
  if (!url || url === "undefined") {
    // seriously
    return `http://localhost:8000`;
  }
  return url;
}

const baseURL = getBaseURL();

function SEO({ description, meta, title, image, tags, ...rest }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaTitle = `${
    title || site.siteMetadata.title
  } - Reactemplates`;
  const metaDescription =
    description || site.siteMetadata.description;
  const metaImage = image || (baseURL + "/assets/images/gatsby-icon.png");

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: tags || [],
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:card`,
          content: "summary_large_image",
        },
        {
          name: `author`,
          content: site.siteMetadata.author,
        },
        {
          name: `publisher`,
          content: "reactemplates",
        },
        {
          name: `og-image`,
          content: metaImage,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
