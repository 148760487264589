import styled from "styled-components";
import Container from "@components/Container";

const NavbarMobileNav = styled.div`
  position: absolute;
  width: 100%;
  height: 70px;
  color: white;
`;

export default NavbarMobileNav;
